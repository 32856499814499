<template>
  <div class="generate-report-card-sheet">
    <intract-bottom-sheet
      title="Generate Report Card"
      :visible="visible"
      @close="closeGenerateSheet"
      description="Create and publish a grade sheet by selecting a class and grading parameters."
      persistent
    >
      <v-form
        v-model="reportCardForm"
        ref="reportCardForm"
        @submit.prevent="null"
        class="mx-4 mt-1"
      >
        <!-- text field -->
        <v-text-field
          required
          class="mb-2"
          outlined
          hide-details="auto"
          label="Title"
          :rules="[rules.required]"
          v-model="newReportCardDetails.title"
        >
        </v-text-field>

        <v-select
          label="Classroom"
          v-model="newReportCardDetails.room"
          :items="rooms"
          item-text="sections"
          item-value="id"
          outlined
          class="mb-2"
          hide-details="auto"
          :rules="[rules.required]"
        >
        </v-select>

        <v-autocomplete
          label="Parameter Groups"
          v-model="newReportCardDetails.parameter_groups"
          :items="allParameterGroups"
          item-text="title"
          hint="Select the grading parameter groups. You can setup new by clicking the 'settings' icon above."
          persistent-hint
          item-value="id"
          class="mb-2"
          outlined
          small-chips
          deletable-chips
          hide-details="auto"
          :rules="[rules.requiredArray]"
          multiple
        >
        </v-autocomplete>
        <v-btn
          :disabled="!reportCardForm || isLoading"
          block
          @click="
            $router.push({
              name: 'GenerateReportCards',
              query: newReportCardDetails,
            });
            $emit('close');
          "
          color="primary"
          >Next
        </v-btn>
      </v-form>
    </intract-bottom-sheet>
  </div>
</template>

<script>
function initialState() {
  return {
    rules: {
      required: (v) => !!v || "Required",
      requiredArray: (v) => !!(v && v.length) || "Required",
    },
    // visible: false,
    reportCardForm: false,
    newReportCardDetails: {
      title: null,
      room: null,
      parameter_groups: [],
    },
    allParameterGroups: [],
  };
}
import IntractBottomSheet from "@components/generics/IntractBottomSheet.vue";
import Mixins from "@utils/mixins";
export default {
  name: "GenerateReportCardSheet",
  mixins: [Mixins.essentials],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    rooms: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return initialState();
  },
  components: {
    IntractBottomSheet,
  },
  methods: {
    async closeGenerateSheet() {
      this.$emit("close");
      Object.assign(this.$data, initialState());
      this.getParameterGroups();
      this.$refs.reportCardForm.reset();
    },

    async getParameterGroups() {
      var url = this.Helper.addUrlParams(
        this.endpoints.reportCardParameterGroupViewSet,
        [`paginate=false`]
      );
      this.allParameterGroups = await this.api.call(this.essentials, url);
    },
  },
  created() {
    this.getParameterGroups();
  },
};
</script>

<style>
</style>