<template>
  <div class="intract-list-sheet">
    <v-bottom-sheet
      scrollable
      :width="$vuetify.breakpoint.mdAndDown ? '100%' : '60%'"
      :value="visible"
      :persistent="persistent"
      @click:outside="!persistent ? $emit('close'): null"
    >
      <v-card>
        <!-- <v-card-title v-if="title" class="pb-2">{{ title }}</v-card-title> -->
        <v-card-title class="pb-2" v-if="title">
          {{ title }}<v-spacer></v-spacer>
 
          <v-btn icon @click="$emit('close')"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-subtitle v-if="description" class="pb-2 pt-1">{{
          description
        }}</v-card-subtitle>
        <v-card-text class="px-2">
          <slot></slot>
        </v-card-text>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>

<script>
export default {
  name: "IntractBottomSheet",
  props: {
    title: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    
  },
};
</script>
<style>
</style>